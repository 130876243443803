import { environment } from '../../../environments/environment';

export const API_DOMAIN_NAME = environment.apiUrl;

export const API_NAME = {
  // get_hospital_name: `get-hospital-data`,
  get_hospital_name: 'get-hospital-injector-data',
  get_summary: `Get_FullSummary`,
  get_repeat_injection: `repeat_injection`,
  get_data_completeness: `data_completeness`,
  get_saline_test_inject: `saline_test_inject`,
  get_flow_rate_limit: `flow_rate_limit`,
  abort_with_repeat: `Abort_with_Repeat`,
  contrast_waste: `contrast_waste`,
  get_license_data: `get_license_data`,
  get_technologist_abort: `TechnologistAbort`,
  get_all_languages: `language_display`,
  get_all_regions: `region_display`,
  update_region_language: `saveuserlocale`,
  get_user_location: `getuserlocale`,
  get_lost_time: `Lost_Time`,
  users_details: `users`,
  get_summary_top_indicators: `get_combined_summary_and_indicators`,
  business_accounts: `business_accounts`,
  get_token: `login`,
  get_summary_key_indicators: `SP_keyIndicators`,
  add_user: `users`,
  deactivate_user: `users/bulk_deactivate`,
  bulk_user: `users/bulk_create`,
  get_standard_report: 'report_page',
  get_master_accounts: 'master_accounts',
  get_protocol: 'getprotocol',
  save_customer_report: 'report_page_custom_save_report',
  update_price: 'update_price',
  get_user_report_template: 'report_page_custom_get_reports_by_email',
  delete_user_report: 'report_page_custom_delete_report',
  update_user_report: 'custom-report/update',
  load_user_report: 'report_page_custom_get_report',
  save_installed_products: 'installed_products',
};
