import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_NAME } from '@app/shared/utils/api-util';

import { IUserProfile } from '@layout/models/layout-model';

declare const gigya: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private readonly http: HttpClient) {}

  getProfileDetails() {
    const profile = localStorage?.getItem('profile') as string;

    try {
      const profileDetails = JSON?.parse(profile);
      return profileDetails as IUserProfile;
    } catch (error) {
      return {} as IUserProfile;
    }
  }

  setAuthorize(params: any) {
    localStorage.setItem('isAuthorized', params);
  }

  getAuthorize() {
    const authorize =
      (localStorage.getItem('isAuthorized') as string) || 'false';
    try {
      const isAuthorized = JSON?.parse(authorize);
      return isAuthorized as boolean;
    } catch (error) {
      return false as boolean;
    }
  }

  isLoggedIn() {
    const loggedIn = (localStorage.getItem('isLoggedIn') as string) || 'false';
    try {
      const isLoggedIn = JSON?.parse(loggedIn);
      return isLoggedIn as boolean;
    } catch (error) {
      return false as boolean;
    }
  }

  redirectToURL(path: string) {
    window.location.href = window.location.origin + `/${path}`;
  }

  getToken(code: any) {
    return this.http.post(`${API_NAME.get_token}`, { code });
  }

  saveTokens(tokendata: any) {
    localStorage.setItem('accessToken', tokendata.access_token);
    localStorage.setItem('isLoggedIn', 'true');
    this.redirectToURL('');
  }

  logOut() {
    gigya.accounts.logout({
      callback: (response: any) => {
        if (response.errorCode === 0) {
          localStorage.clear();
          this.redirectToURL('login');
        }
      },
    });
  }
}
